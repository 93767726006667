import styled from 'styled-components'

import {
	InputError as SuperInputError,
	Span as SuperSpan,
	SubmitButtonRed as SuperSubmitRedButton
} from '../superSetCommons'

interface IInput {
	error?: boolean
	name?: string
	halfField?: boolean
}

export const InputError = styled(SuperInputError)``

export const SubmitButtonRed = styled(SuperSubmitRedButton)``

export const ComponentWrapper = styled.div`
	background: #f7f7f7;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`

export const FormWrapper = styled.div`
	padding: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 16px #00000019;
	border: 1px solid #e9e9e9;
	border-radius: 10px;
`

export const FormFieldWrapper = styled.div`
	margin: 16px 0 0;
`

export const FormLabel = styled(SuperSpan)`
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	display: block;
`

export const FormInputContainer = styled.div<IInput>`
	padding: 5px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: solid 1px
		${props => (props.error ? props.theme.colors.red_alert : props.theme.colors.secundary_border)};
`

export const InputContainer = styled.div`
	width: 90%;
`

export const IconContainer = styled.div`
	width: 10%;
`

export const FormInput = styled.input<IInput>`
	display: flex;
	width: 100%;
	outline: none;
	border: none;
	text-align: left;
	font: 14px/32px CentraleSansRegular;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	&::placeholder {
		color: ${props => props.theme.colors.font_disabled};
	}
	padding-left: 3%;

	@media (max-width: 1340px) {
		font: 12px/32px CentraleSansRegular;
	}
	@media (max-width: 768px) {
		font: 10px/32px CentraleSansRegular;
	}
`

export const Form = styled.div`
	margin: auto;
`

export const WelcomeMesage = styled(SuperSpan)`
	display: block;
	margin: 0px;
	padding: 20px 0 0 0;
	line-height: 2;
	text-align: left;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	max-width: 80%;

	strong {
		font-family: CentraleSans-Bold;
	}
`

export const Logo = styled.img`
	display: block;
	width: 100%;
	max-width: 350px;
`

export const ContainerCheckbox = styled.label`
					display: flex;
					// position: relative;
					// padding-left: 35px;
					margin: 15px 0 0 0;
					cursor: pointer;
					font-size: 12px;
					-webkit-user-select: none;
					-moz-user-select: none;
					-ms-user-select: none;
					user-select: none;
					line-height: 1.4;

					&:hover input ~ .checkSpan {
						border: 1px solid ${props => props.theme.colors.font_highlight}74;
						box-shadow: 0px 0px 6px ${props => props.theme.colors.font_highlight}74;
					}

					// input[type='checkbox'] {
					// 	position: absolute;
					// 	opacity: 0;
					// 	cursor: pointer;
					// 	height: 0;
					// 	width: 0;
					// }

					// .checkSpan {
					// 	position: absolute;
					// 	top: -2px;
					// 	left: 0;
					// 	height: 20px;
					// 	width: 20px;
					// 	background-color: transparent;
					// 	border: 1px solid ${props => props.theme.colors.button_disabled};
					// 	box-shadow: 0px 0px 6px ${props => props.theme.colors.button_disabled_border};
					// 	border-radius: 2px;
					// }

					// input:checked ~ .checkSpan {
					// 	border: 1px solid ${props => props.theme.colors.font_highlight};
					// }

					// /* Create the checkmark/indicator (hidden when not checked) */
					// .checkSpan:after {
					// 	content: '';
					// 	position: absolute;
					// 	display: none;
					// }

					// /* Show the checkmark when checked */
					// input:checked ~ .checkSpan:after {
					// 	display: block;
					// }

					// /* Style the checkmark/indicator */
					// .checkSpan:after {
					// 	left: 6px;
					// 	top: -1px;
					// 	width: 5px;
					// 	height: 13px;
					// 	border: 1px solid ${props => props.theme.colors.font_highlight};
					// 	box-shadow: 0px 0px ${props => props.theme.colors.font_highlight}74;
					// 	border-width: 0 3px 3px 0;
					// 	-webkit-transform: rotate(45deg);
					// 	-ms-transform: rotate(45deg);
					// 	transform: rotate(45deg);
					// }
				`

export const ConsentText = styled.p`
	text-align: left;
	font: 14px/20px CentraleSansRegular;
	letter-spacing: 0;

	a {
		text-align: left;
		text-decoration: underline;
		font: Bold 14px/20px CentraleSans-Bold;
		letter-spacing: 0;
		color: ${props => props.theme.colors.font_highlight};
	}
`

export const FormLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

export const Eye = styled.img``
