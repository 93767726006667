import SEO from '@cuidardigital/commons/components/Seo/seo'
import React from 'react'
import PageNewPassword from '../../components/NewPassword/'

export default (props: any) => (
	<>
		<SEO />
		<PageNewPassword {...props} />
	</>
)
