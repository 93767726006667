import base64 from 'base-64'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import utf8 from 'utf8'

import { Loader } from '@cuidardigital/commons/components/Loader'
import Tooltip from '@cuidardigital/commons/components/Tooltip'
import {
	deteleResetPassword,
	getRegistring,
	getTokenPassword,
	getUserName,
	logout,
	resetPassword
} from '@cuidardigital/commons/services/auth'
import callResetPassword from '@cuidardigital/commons/services/reset-password'
import { passwordValidator } from '@cuidardigital/commons/utils/validator'

import closedEye from '@cuidardigital/commons/assets/svg/icon-eye-off.svg'
import openedEye from '@cuidardigital/commons/assets/svg/icon-eye-open.svg'
import Checkbox from '@cuidardigital/commons/components/Checkbox'
import {
	ComponentWrapper,
	ConsentText,
	ContainerCheckbox,
	Eye,
	Form,
	FormFieldWrapper,
	FormInput,
	FormInputContainer,
	FormLabel,
	FormLabelContainer,
	FormWrapper,
	IconContainer,
	InputContainer,
	InputError,
	Logo,
	SubmitButtonRed,
	WelcomeMesage
} from './styles'
import MessageModal from '../Modal'
import { NEW_PASSWORD } from '../../routes'
import logo from '../../assets/img/logo.png'

interface IModalObj {
	title?: string
	message: string
	buttonText?: string
	onClose?: string
}

interface IProps {
	location: IObjectLiteral
}

interface IObjectLiteral {
	[key: string]: any
}

interface IFormState {
	password?: string
	password2?: string
	optins2?: any[]
	optins3?: any[]
}

const formInitialState = {
	password: '',
	password2: '',
	optins2: false
}

const errorsInitialState: any = {
	...Object.keys(formInitialState).reduce((value, item) => ({ ...value, [item]: false }), {})
}

const passwordTooltip = `
  A sua senha deve ter no mínimo 8 caracteres contendo pelo menos: 1 letra maiúscula e 1 número.
`

const NewPassword: React.FC<IProps> = ({ location }) => {
	// @ts-ignore
	const [formValues, setFormValues] = useState<IFormState>(formInitialState)
	const [errors, setErrors] = useState(errorsInitialState)
	const [formIsWrong, setFormIsWrong] = useState(true)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalObj>({ message: '' })
	const [showModal, setShowModal] = useState({ open: false, reset: true })
	const [success, setSuccess] = useState(false)
	const [registring, setRegistring] = useState(true)
	const [revealPassword, setRevealPassword] = useState({
		password: false,
		password2: false
	})

	useEffect(() => {
		initializeComponent()
	}, [])

	useEffect(() => {
		normalizerErrors()
	}, [errors])

	useEffect(() => {
		handleValidatePassword()
	}, [formValues.password, formValues.password2])

	const qsValues = queryString.parse(location.search)
	if (qsValues && qsValues.param) {
		try {
			const bytes = base64.decode(qsValues.param)
			const params = JSON.parse(utf8.decode(bytes))

			// Informe no GA que a página foi acessada via SMS
			if (qsValues.sms) {
				ReactGA.event({
					category: 'SMS',
					action: 'Usuário cadastro de senha',
					label: `${params.username} preenchendo senha`
				})
			}

			if (params.accessToken && params.username) {
				resetPassword(params)
				navigate(NEW_PASSWORD)
			} else {
				deteleResetPassword()
				logout()
			}
		} catch {
			deteleResetPassword()
			logout()
		}
		// } else {
		// 	if (!getTokenPassword()) {
		// 		logout()
		// 	}
	}

	const handleConsent2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		setFormValues({ ...formValues, optins2: checked })
		setErrors({ ...errors, consent: !checked })
	}

	const handleConsent3 = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		setFormValues({ ...formValues, optins3: checked })
		setErrors({ ...errors, consent: !checked })
	}

	const handleValidatePassword = () => {
		const { password, password2 } = formValues
		if (password && password.length > 7) {
			const isValid = passwordValidator(password)
			setErrors({ ...errors, password: !isValid, password2: password2 !== password })
		}
	}

	const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { password } = formValues
		if (password !== value) {
			setFormValues({ ...formValues, password: value })
		}
	}

	const handlePassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { password2 } = formValues
		if (password2 !== value) {
			setFormValues({ ...formValues, password2: value })
			setErrors({ ...errors, password2: value !== formValues.password })
		}
	}

	const handlePasswordBlur = e => {
		const { value } = e.target
		const isValid = passwordValidator(value)
		setErrors({ ...errors, password: !isValid })
	}

	const handlePassword2Blur = e => {
		const { value } = e.target
		const { password } = formValues
		setErrors({ ...errors, password2: password !== value })
	}

	const handleSubmit = async () => {
		setLoading(true)

		if (!formValues.password || !formValues.password2) {
			setError('Por favor, preencha todos os campos para continuar.')
			setLoading(false)
		} else {
			try {
				let optins
				if (formValues.optins2) optins = ['use']
				// @ts-ignore
				callResetPassword(getUserName(), formValues.password, getTokenPassword(), optins)
					.then(response => {
						if (response.status === 204) {
							setSuccess(true)
						} else {
							setMessageToShow({ message: response.data.message, onClose: setShowMessage })
							setShowMessage(true)
							setShowModal({ open: false })
						}
					})
					.catch(e => {
						setMessageToShow({
							message: JSON.stringify(e),
							onClose: setShowMessage
						})
						setShowMessage(true)
					})
					.finally(() => {
						// setShowMessage(true)
						setLoading(false)
					})
			} catch (error) {
				setLoading(false)
				setError('Houve um problema no fluxo de login.')
			}
		}
	}

	const handleRevealPassword = (param: 'password' | 'password2') => {
		setRevealPassword(prev => ({ ...prev, [param]: !revealPassword[param] }))
	}

	const modalMessageParam = {
		onClose: messageToShow ? messageToShow.onClose : setShowModal,
		title: messageToShow ? messageToShow.title : '',
		message: messageToShow ? messageToShow.message : '',
		maxWidth: '450px'
	}

	const initializeComponent = () => {
		const firstAccess = `${getRegistring()}`
		if (firstAccess !== 'true') setRegistring(false)
		setTimeout(() => setLoading(false), 500)
	}

	const normalizerErrors = () => {
		let formErros = { ...errors }
		let values = { ...formValues }
		if (!registring) {
			formErros = { ...errors, optins2: false }
			values = { ...formValues, optins2: true }
		}
		const wrong = Object.values(formErros).some(b => b) || Object.values(values).some(v => !v)
		const isValid = passwordValidator(formValues.password)
		setFormIsWrong(wrong || !isValid)
	}

	if (error !== '') {
		// @ts-ignore
		setMessageToShow({ message: error, onClose: setShowMessage })
		setShowMessage(true)
		setError('')
	}

	if (loading) return <Loader />

	return (
		<>
			{loading && <Loader />}
			{showMessage && <MessageModal {...modalMessageParam} />}

			<ComponentWrapper>
				<FormWrapper>
					<Form>
						<Logo src={logo} />
						{success ? (
							<>
								{registring ? (
									<WelcomeMesage wrap fontSize='16px/32px'>
										<strong>Cadastro confirmado!</strong>
										<br />
										Seu cadastro foi confirmado com sucesso.
									</WelcomeMesage>
								) : (
									<WelcomeMesage wrap fontSize='16px/32px'>
										<strong>Pronto!</strong>
										<br />
										É só acessar com o seu CPF
										<br />
										cadastrado e sua nova senha
									</WelcomeMesage>
								)}
								<SubmitButtonRed id='button-new-password-logout' onClick={logout}>
									Ok, entendi
								</SubmitButtonRed>
							</>
						) : (
							<>
								<WelcomeMesage wrap fontSize='16px/32px'>
									Olá! <br />
									Por favor escolha sua senha.
								</WelcomeMesage>
								<FormFieldWrapper>
									<FormLabelContainer>
										<FormLabel fontSize='14px/24px'>Senha</FormLabel>
										<Tooltip content={passwordTooltip} />
									</FormLabelContainer>
									<FormInputContainer>
										<InputContainer>
											<FormInput
												id='password'
												name='password'
												error={errors.password}
												value={formValues.password}
												onChange={handlePassword}
												onBlur={handlePasswordBlur}
												placeholder='Digite sua senha'
												halfField
												type={revealPassword.password ? 'text' : 'password'}
											/>
										</InputContainer>
										<IconContainer>
											<Eye
												// tslint:disable-next-line: jsx-no-lambda
												onClick={() => handleRevealPassword('password')}
												src={revealPassword.password ? openedEye : closedEye}
											/>
										</IconContainer>
									</FormInputContainer>
									{errors.password && <InputError fontSize='12px/18px'>Senha inválida</InputError>}
								</FormFieldWrapper>
								<FormFieldWrapper>
									<FormLabel fontSize='14px/24px'>Confirmar senha</FormLabel>
									<FormInputContainer>
										<InputContainer>
											<FormInput
												id='password2'
												name='password2'
												error={errors.password2}
												value={formValues.password2}
												onChange={handlePassword2}
												onBlur={handlePassword2Blur}
												placeholder='Confirme sua senha'
												halfField
												type={revealPassword.password2 ? 'text' : 'password'}
											/>
										</InputContainer>
										<IconContainer>
											<Eye
												src={revealPassword.password2 ? openedEye : closedEye}
												// tslint:disable-next-line: jsx-no-lambda
												onClick={() => handleRevealPassword('password2')}
											/>
										</IconContainer>
									</FormInputContainer>
									{errors.password2 && (
										<InputError fontSize='12px/18px'>
											{errors.password ? 'Senha inválida' : 'Senhas não conferem'}
										</InputError>
									)}
								</FormFieldWrapper>
								{registring && (
									<>
										<FormFieldWrapper>
											<ContainerCheckbox>
												<Checkbox
													id='checkbox-use-term'
													onChange={handleConsent2}
													checked={formValues.optins2}
												/>
												<ConsentText>
													Aceito os
													<span>
														<a
															href='https://cosmic-s3.imgix.net/06ffd530-78fd-11ea-8d50-451e1995573b-Termos-de-Uso---Cuidar-Digital---GERAL.pdf'
															rel='noopener noreferrer'
															target='_blank'
														>
															<strong> Termos de Uso </strong>
														</a>
													</span>
													da Bradesco Saúde.
												</ConsentText>
											</ContainerCheckbox>
										</FormFieldWrapper>
									</>
								)}
								<SubmitButtonRed
									id='button-create-password'
									disabled={formIsWrong}
									onClick={handleSubmit}
								>
									Criar
								</SubmitButtonRed>
							</>
						)}
					</Form>
				</FormWrapper>
			</ComponentWrapper>
		</>
	)
}

export default NewPassword
